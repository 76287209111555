<template>
  <div>
    <v-card width="400px">
      <v-card-title>Mot de passe oublié</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-alert type="success" v-if="success">
          Email de réinitialisation envoyé.
        </v-alert>
        <p>Réinitialisez votre mot de passe.</p>
        <p>
          Vous recevrez un email avec un lien pour créer un nouveau mot de
          passe.
        </p>
        <v-form @submit.prevent.stop="submit">
          <TextField
            :field="form.email"
            label="Votre adresse email"
          ></TextField>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :to="{ name: 'Login' }" exact text>
          <v-icon left>mdi-chevron-left</v-icon>
          Retour
        </v-btn>
        <v-btn
          text
          class="ml-auto"
          @click="submit"
          :loading="loading"
          :disabled="!canSend"
          color="success"
          >Réinitialiser</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import TextField from '@/components/form/TextField'
import Validation from '@/components/mixins/Validation'

export default {
  name: 'ForgetPassword',
  components: { TextField },
  mixins: [Validation],
  data() {
    return {
      form: {
        email: {
          value: '',
          required: true,
        },
      },
      canSend: true,
      loading: false,
      error: '',
      success: false,
    }
  },
  methods: {
    submit() {
      this.formValidation(this.form, true)
      if (this.errorsNbr > 0) {
        return
      }
      this.loading = true
      requestService
        .post('api/user/send-reset-password', {
          email: this.form.email.value,
        })
        .then(response => {
          if (!response.success) {
            this.$set(this.form.email, 'errors', [response.message])
          } else {
            this.success = true
            this.canSend = false
            setTimeout(() => (this.canSend = true), 30000)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style scoped></style>
